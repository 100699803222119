<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ribbonText() {
      const {
        DesktopLeft,
        DesktopCenter,
        DesktopRight,
        Phone,
      } = this.block.fields;

      return { DesktopLeft, DesktopCenter, DesktopRight, Phone };
    },
    backgroundColor() {
      return this.block.fields.BackgroundColor || '#000';
    },
    foregroundColor() {
      return this.block.fields.ForegroundColor || '#FFF';
    },
    showRibbon() {
      return Object.values(this.ribbonText).some((x) => x !== undefined);
    },
  },
};
</script>

<template>
  <div class="ribbon-block" v-if="showRibbon" :style="{color: foregroundColor, backgroundColor: backgroundColor}">
    <div class="ribbon-block__text-container">
      <span
        class="ribbon-block__desktop ribbon-block__desktop-left"
        v-if="ribbonText.DesktopLeft"
        v-html="ribbonText.DesktopLeft"
      >
      </span>
      <span
        class="ribbon-block__desktop ribbon-block__desktop-center"
        v-if="ribbonText.DesktopCenter"
        v-html="ribbonText.DesktopCenter"
      >
      </span>
      <span
        class="ribbon-block__desktop ribbon-block__desktop-right"
        v-if="ribbonText.DesktopRight"
        v-html="ribbonText.DesktopRight"
      >
      </span>
      <span
        class="ribbon-block__phone"
        v-if="ribbonText.Phone"
        v-html="ribbonText.Phone"
      > </span>
    </div>
  </div>
</template>

<style>
.ribbon-block {
  width: 100vw;
  max-width: 100%;
  white-space: nowrap;
}

.ribbon-block__text-container {
  height: 35px;
  max-width: var(--layout-maxWidth);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  font-family: var(--font-proxima-nova-bold);
  text-transform: uppercase;
}

.ribbon-block__desktop {
  display: none;
}

.ribbon-block__phone {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 8px;
}

.ribbon-block__desktop a,
.ribbon-block__phone a {
  color: inherit;
  outline: none;
  text-decoration: underline;
}

.ribbon-block__desktop-center {
  padding: 0 3.2rem;
}

@media (min-width: 768px) {
  .ribbon-block__text-container {
    height: 2.5rem;
  }

  .ribbon-block__desktop {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 9.8px;
  }

  .ribbon-block__phone {
    display: none;
  }
}
</style>
